import { AppRoute } from './models/common/AppRoute';
import DashboardPage from './pages/DashboardPage';
import DataVersionsPage from './pages/DataVersionsPage';
import InformativesPage from './pages/InformativesPage';
import CompaniesPage from './pages/CompaniesPage';
import QuestionsPage from './pages/QuestionsPage';
import ConAnswerPage from './pages/AWQuestionPage';
import AnswerPage from './pages/AnswerPage';
import QuestionInformativePage from './pages/QuestionInformativePage';
import NestedAnswerPage from './pages/NestedAnswerPage';
import QuestionNestedInformativePage from './pages/QuestionNestedInformativePage';
import DataPage from './pages/DataPage';
import ErrorPage from './pages/ErrorPage';
import SimpleAnswerPage from './pages/SimpleAnswerPage';
import SimpleQuestionPage from './pages/SimpleQuestionPage';
import AWQuestionPage from './pages/AWQuestionPage';

const AppRoutes : AppRoute[] = [
    {
        path: "/",
        name: "Dashboard",
        component: DashboardPage,
        isVisible: false
    },
    {
        path: "/unauthorised",
        name: "Error",
        component: ErrorPage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType",
        name: "Data Versions",
        component: DataVersionsPage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId",
        name: "Data",
        component: DataPage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId/questions",
        name: "Questions",
        component: QuestionsPage,
        isVisible: false
    },
    {
        path: "/SW/dataversions/:productType/data/:versionId/questions/:qId",
        name: "Answers",
        component: SimpleQuestionPage,
        isVisible: false
    },
    {
        path: "/AW/dataversions/:productType/data/:versionId/questions/:qId",
        name: "Answers",
        component: AWQuestionPage,
        isVisible: false
    },
    {
        path: "/AW/dataversions/:productType/data/:versionId/questions/:qId/answers",
        name: "Answers",
        component: ConAnswerPage,
        isVisible: false
    },
    {
        path: "/SW/dataversions/:productType/data/:versionId/questions/:qId/answers",
        name: "Answers",
        component: SimpleAnswerPage,
        isVisible: false
    },
    {
        path: "/SW/dataversions/:productType/data/:versionId/questions/:qId/answers/:aId",
        name: "Answers",
        component: SimpleAnswerPage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId/questions/:qId/answers/:asId/:aId",
        name: "Answer",
        component: AnswerPage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId/questions/:qId/answers/:asId/:aId/nested/:naId",
        name: "Answer",
        component: NestedAnswerPage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId/questions/:qId/informative/:iId",
        name: "Question Informative",
        component: QuestionInformativePage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId/questions/:qId/informative/:iId/nested/:nId",
        name: "Question Nested Informative",
        component: QuestionNestedInformativePage,
        isVisible: false
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId/informatives",
        name: "Informatives",
        component: InformativesPage,
        isVisible: true
    },
    {
        path: "/:companyCode/dataversions/:productType/data/:versionId/companies",
        name: "Companies",
        component: CompaniesPage,
        isVisible: true
    }
];

export default AppRoutes;