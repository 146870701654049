import { Alert, Box, Divider, Grid, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'
import { useEndpoints } from '../utils/EndpointContext';
import { defaultValues } from '../models/defaultValues';
import { FileModel } from '../models/FileModel';
import TextInput from '../components/forms/TextInput';
import { SimpleQuestion } from '../models/SimpleQuestionModel';
import { useMsal } from '@azure/msal-react';
import ConfirmDeleteModal from '../components/modals/ConfirmDeleteModal';
import { Informative } from '../models/InformativeModel';
import DropdownInput from '../components/forms/DropdownInput';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
    qId: string;
};

const SimpleQuestionPage: React.FC = (props: Props) => {
    const { companyCode, versionId, qId } = useParams<Params>();
    
    const endPoints = useEndpoints();
    const navigate = useNavigate();
    const { accounts } = useMsal();

    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);
    const [savedData, setSavedData] = useState<FileModel>();
    const [question, setQuestion] = useState<SimpleQuestion>(defaultValues.simpleQuestion);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [informatives, setInformatives] = useState<Informative[]>([]);
    
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    useEffect(() => {
        axios.get(`${endPoints.questionEditorRead}`,{
            params: {
                partitionKey: 'SW',
                rowKey: versionId,
                downloadData: true
            }
        })
        .then(response => {
            setSavedData(response.data);
            
            let questionData = response.data.Data!.Questions.find((question: SimpleQuestion) => question.Id == qId);
            setInformatives(response.data.Data!.Informatives);

            if(questionData)
            {
                let questionIndexData = response.data.Data!.Questions.findIndex((question: SimpleQuestion) => question.Id == qId);
                setQuestionIndex(questionIndexData);
                setQuestion(questionData);
            }

        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }
      }, [itemAdded]);

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = async () => {
        try {
            savedData!.Data!.Questions[questionIndex] = question;
            savedData!.ModifiedBy = accounts[0]?.name;
            await axios.post(`${endPoints.questionEditorUpdate}`, savedData);
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAlert(true);
        }
    };

    function handleIdChange(value: string): void {
        setQuestion(prevQuestion => ({ ...prevQuestion, Id: value }));
    }

    function handleSectionChange(value: string): void {
        setQuestion(prevQuestion => ({ ...prevQuestion, Section: value }));
    }

    function handleQuestionChange(value: string): void {
        setQuestion(prevQuestion => ({ ...prevQuestion, Question: value }));
    }

    function handleFileUploadRequiredChange(value: boolean): void {
        setQuestion(prevQuestion => ({ ...prevQuestion, FileUploadRequired: value }));
    }

    const handleRemove = (index: number) => {
        setDeleteIndex(index);
        setOpenDeleteModal(true);
    };

    const confirmRemove = () => {
        if (deleteIndex !== null) {
            question.Answers?.splice(deleteIndex, 1);
            setQuestion(prevQuestion => ({ ...prevQuestion, Answers: question.Answers }));
            handleSave();
            setOpenDeleteModal(false);
        }
    };

    const renderInformative = (informativeId: string) => {
        return informatives.map((informative) => {
            if(informative.Id === informativeId){
                return <><small><strong>{informative.Id}</strong> | {informative.Content}</small></>;
            }

            return (<></>);
        });
    }

    return(
        <div>
            {question ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question<SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    <Typography>Question</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <TextInput fieldName={'id'} label={'ID'} value={question.Id} onChange={(value) => handleIdChange(value)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput fieldName={'section'} label={'Section'} value={question.Section} onChange={(value) => handleSectionChange(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput fieldName={'question'} label={'Question'} value={question.Question} onChange={(value) => handleQuestionChange(value)} />
                                </Grid>
                                <Grid item xs={2}>
                                <DropdownInput 
                                    label='File Upload Required' 
                                    fieldName={`Value`} 
                                    value={question.FileUploadRequired ? 'true' : 'false'} 
                                    items={[{
                                        key: "true",
                                        value: "true",
                                        description: null
                                    },
                                    {
                                        key: "false",
                                        value: "false",
                                        description: null
                                    }]} 
                                    onChange={(value) => { value === 'true' ? handleFileUploadRequiredChange(true) : handleFileUploadRequiredChange(false) }} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Typography>Answers</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            {question.Answers?.map((answer, index) => (
                                <Stack key={index}>
                                    <Box sx={{ m: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10}>
                                                <Box><strong>Condition:</strong> {answer.Condition}</Box>
                                                <Box><strong>Summary:</strong> {answer.Summary}</Box>
                                                <Box><strong>Full Answer: </strong> {ReactHtmlParser(answer.Answer)}</Box>
                                                <Box><small><strong>Informatives:</strong></small>
                                                <Stack spacing={2}>
                                                        {answer.Informatives?.map((item, index) => (
                                                            renderInformative(item)
                                                        ))}
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} display="flex" justifyContent="flex-end" >
                                                <Button color='error' onClick={() => handleRemove(index)}> <DeleteIcon /></Button>
                                                <Button variant="contained" color='secondary' onClick={() => navigate(`answers/${index}`)}><EditIcon /></Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider variant="middle" />
                                </Stack>
                                ))}
                            <Stack>
                                <Box sx={{ m: 2 }} display="flex" justifyContent="flex-end" className="tools">
                                    <Button variant='contained' color='primary' onClick={() => navigate(`answers`)}>Add Answer <CreateIcon /></Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Paper>
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Question updated successfully.
                    </Alert>
                </Snackbar>
                <ConfirmDeleteModal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} onConfirm={confirmRemove} />
            </>
            : <>Loading Question</>}
        </div>
    );
}

export default SimpleQuestionPage;