import { Alert, Box, Divider, Grid, Paper, Snackbar, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { conditionTypes } from '../data/ConditionTypes';
import DropdownInput from '../components/forms/DropdownInput';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Placeholder from '../components/rte/Placeholder';
import ConditionalLineComponent from '../components/Conditions';
import { getQuestions } from '../data/QuestionAndAnswerService';
import { Answer } from '../models/AnswerModel';
import { ConAnswer } from '../models/ConAnswerModel';
import TextInput from '../components/forms/TextInput';
import { Question } from '../models/QuestionModel';
import { useEndpoints } from '../utils/EndpointContext';
import { defaultValues } from '../models/defaultValues';
import { FileModel } from '../models/FileModel';
import { useMsal } from '@azure/msal-react';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
    qId: string;
    asId: string;
    aId: string;
};

const AnswerPage: React.FC = (props: Props) => {
    const endPoints = useEndpoints();
    const navigate = useNavigate();
    const { accounts } = useMsal();

    let { versionId, qId, asId, aId } = useParams<Params>();
    const answerSetIndex = (asId) ? parseInt(asId!) : 0;
    const answerIndex = (aId) ? parseInt(aId!) : 0;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [savedData, setSavedData] = useState<FileModel>(defaultValues.file);
    const [conAnswer, setConAnswer] = useState<ConAnswer>(defaultValues.conAnswer);
    const [conAnswerIndex, setConAnswerIndex] = useState<number>(0);
    const [answer, setAnswer] = useState<Answer>(defaultValues.answer);
    const [question, setQuestion] = useState<Question>(defaultValues.question);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [questionIndex, setQuestionIndex] = useState<number>(0);

    useEffect(() => {

        axios.get(`${endPoints.questionEditorRead}`,{
            params: {
                partitionKey: 'AW',
                rowKey: versionId,
                downloadData: true
            }
        })
            .then(response => {
                setSavedData(response.data);

                let conQuestion = response.data.Data!.Questions.find((element : Question) => element.Id === qId);
                let answersData = response.data.Data!.Answers.find((element: ConAnswer) => element.QuestionId == qId);

                if(answersData){

                    let answersIndex = response.data.Data!.Answers.findIndex((element: ConAnswer) => element.QuestionId == qId);

                    setQuestion(conQuestion);
                    setConAnswer(answersData);
                    setConAnswerIndex(answersIndex);
                    setQuestionIndex(response.data.Data!.Answers.indexOf(answersData));

                    let answser: Answer = answersData.AnswersSet[answerSetIndex].Answers[answerIndex];

                    setAnswer(answser);

                    setEditorState(EditorState.createWithContent(stateFromHTML(answser.Answer)));
                }
            });
    }, []);

    const handleEditorChange = (state: EditorState) => {
        setEditorState(state);
        handleAnswerChange(stateToHTML(state.getCurrentContent()));
    }

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = async () => {
        
        try {
            savedData.Data!.Answers[conAnswerIndex].AnswersSet[answerSetIndex].Answers[answerIndex] = answer;
            savedData!.ModifiedBy = accounts[0]?.name;

            await axios.post(`${endPoints.questionEditorUpdate}`, savedData);
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAlert(true);
        }

        setOpenAlert(true);
    };

    const handleConditionAdd = () => {
        const newData = { ...answer };
        newData.Conditions = [...newData.Conditions, defaultValues.condition];
        setAnswer(newData);
        handleSave();
    };

    const handleAnswerChange = useCallback((value: string) => {
        const newData = { ...answer };
        newData.Answer = value;
        setAnswer(newData);
    }, [answer]);

    const handleSummaryChange = useCallback((value: string) => {
        const newData = { ...answer };
        newData.Summary = value;
        setAnswer(newData);
    }, [answer]);

    const handleTypeChange = useCallback((value: string) => {
        const newData = { ...answer };
        newData.Type = value;
        setAnswer(newData);
    }, [answer]);

    const handleConditionKeyChange = useCallback((value: string, conditionIndex: number) => {
        const newData = { ...answer };
        newData.Conditions[conditionIndex].Key = value;
        newData.Conditions[conditionIndex].Value = "";
        setAnswer(newData);
    }, [answer]);

    const handleConditionValueChange = useCallback((value: string, conditionIndex: number) => {
        const newData = { ...answer };
        newData.Conditions[conditionIndex].Value = value;
        setAnswer(newData);
    }, [answer]);

    const handleConditionRemove = (index: number) => {
        const newData = { ...answer };
        newData.Conditions.splice(index, 1);
        setAnswer(newData);
    };

    const handleNestedAnswerAdd = () => {
        const newData = { ...answer };

        if (newData.NestedAnswers == null) { newData.NestedAnswers = []; }

        newData.NestedAnswers = [...newData.NestedAnswers, defaultValues.answer];
        setAnswer(newData);
        handleSave();
    };

    const handleNestedAnswerRemove = (index: number) => {
        const newData = { ...answer };
        newData.NestedAnswers?.splice(index, 1);
        setAnswer(newData);
    };

    const renderAnswer = () => {

        if (answer.Type === 'nested') {
            return (<></>);
        }

        return (<Paper>
            <Typography sx={{ mb: 2 }}>Full Answer</Typography>
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'remove', 'history'],
                }}
                toolbarCustomButtons={[
                    <Placeholder
                        editorState={editorState}
                        onChange={handleEditorChange}
                        options={[...getQuestions(), 'waterSupplierAddress', 'waterSupplierCompanyName', 'sewerageSupplierAddress', 'sewerageSupplierCompanyName']} />]}
            />
        </Paper>);
    }

    const renderNestedAnswerAddButton = () => {
        if (answer.Type !== 'nested') {
            return (<></>);
        }

        return (<Button variant="contained" color='secondary' onClick={handleNestedAnswerAdd}>Add Nested Answer <CreateIcon /></Button>);
    }

    const renderNestedAnswers = () => {

        if (answer.Type !== 'nested') {
            return (<></>);
        }

        return (
            <Box sx={{ m: 2 }}>
                <Stack spacing={2}>
                    {answer.NestedAnswers?.map((nestedAnswer, nestedAnswerIndex) => (
                        <Paper elevation={2} style={{ backgroundColor: "#FAFAFA" }} key={nestedAnswerIndex}>
                            <Stack key={nestedAnswerIndex}>
                                <Box sx={{ m: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                            <Typography>{nestedAnswer.Answer}</Typography>
                                        </Grid>
                                        <Grid display={'flex'} item xs={2} justifyContent="flex-end">
                                            <Button color='error' onClick={() => handleNestedAnswerRemove(nestedAnswerIndex)}> <DeleteIcon /></Button>
                                            <Button variant="contained" color='secondary' onClick={() => navigate(`nested/${nestedAnswerIndex}`) }><EditIcon /></Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider variant="middle" />
                            </Stack>
                        </Paper>
                    ))}
                </Stack>
            </Box>
        )
    }

    return (
        <div>
            {answer ?
                <>
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginBottom: '1em' }}>
                        <Grid item xs={6}>
                           
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" className="tools">
                                <Stack spacing={2} direction="row">
                                    {renderNestedAnswerAddButton()}
                                    <Button variant="contained" color='secondary' onClick={handleConditionAdd}>Add Condition <CreateIcon /></Button>
                                    <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Stack spacing={2}>
                        <Typography>Question</Typography>
                        <Paper>
                            {question.Question}
                        </Paper>
                        <Typography>Answer</Typography>
                        <Paper>
                            <Stack>
                                <Box sx={{ m: 2 }}>
                                    <TextInput label='Summary' fieldName={`Summary`} value={answer.Summary} onChange={(value) => handleSummaryChange(value)} />
                                </Box>
                                <Box sx={{ m: 2 }}>
                                    {renderAnswer()}
                                </Box>
                                <Divider variant="middle" />
                                <Box sx={{ m: 2 }}>
                                    <DropdownInput label='Type' fieldName={`type`} value={answer.Type} items={conditionTypes} onChange={(value) => handleTypeChange(value)} />
                                </Box>
                                <ConditionalLineComponent data={answer} onHandleConditionKeyChange={handleConditionKeyChange} onHandleConditionValueChange={handleConditionValueChange} onHandleConditionRemove={handleConditionRemove} />
                            </Stack>
                        </Paper>
                        <Typography>Nested Answers</Typography>
                        <Paper>
                            {renderNestedAnswers()}
                        </Paper>
                    </Stack>
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: '1em' }}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" className="tools">
                                <Stack spacing={2} direction="row">
                                    {renderNestedAnswerAddButton()}
                                    <Button variant="contained" color='secondary' onClick={handleConditionAdd}>Add Condition <CreateIcon /></Button>
                                    <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Answer updated successfully.
                        </Alert>
                    </Snackbar>
                </> : <>Loading Answer</>}
        </div>
    );
}

export default AnswerPage;