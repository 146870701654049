import './App.css';
import { Box, CssBaseline, Toolbar, Typography, AppBar, Grid, Breadcrumbs, Button } from '@mui/material';
import { useLocation, Route, Routes, useNavigate, Link } from "react-router-dom";

import AppRoutes from "./AppRoutes";
import { AppRoute } from './models/common/AppRoute';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { CustomNavigationClient } from './utils/NavigationClient';
import { loginRequest } from "./authConfig";
import Auth from './components/common/Auth';

import logo from './geodesysLogo.svg';
import { useEffect, useState } from 'react';

interface AppProps {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);

  pca.setNavigationClient(navigationClient);
  const account = pca.getActiveAccount();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const authRequest = { ...loginRequest };

  const location = useLocation();

  useEffect(() => {
    if(account?.idTokenClaims?.extension_PermissionsQuestionEditor === 'EQ'){
      setIsAuthenticated(true);
    }else{
      setIsAuthenticated(false);
    }
  }, []);

  const createBreadcrumbs = () => {
    
    let pathnames = location.pathname.split('/').filter((x) => x);

    return pathnames.map((value, index) => {

      if(value === 'data' || value === 'dataversions' || value === 'answers' || value === 'informative'  || value === 'nested'){
        return;
      }

      const last = (index === pathnames.length - 1 || index === 1 || index === 0 || index === 3);
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;

      return last ? (
        <Typography color="secondary" fontWeight={600} key={to}>
          {value}
        </Typography>
      ) : (
        <Link color="primary" style={{fontWeight: '600'}} to={to} key={to}>
          {value}
        </Link>
      );
    });
  }

  const renderUnauthorisedContent = () => {
    return <main className='main-content'><>Unauthorised<br /><Button onClick={() => window.location.href = '/'}>Refresh</Button></></main>
  }

  const renderContent = () => {
    return <main className='main-content'>
            <Breadcrumbs style={{marginBottom: '1em'}} aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
              <Link color="primary" style={{fontWeight: '600'}} to={"/"} key={"/"}>Dashboard</Link>
              {createBreadcrumbs()}
            </Breadcrumbs>
            <Routes>
              {createRoutes(AppRoutes)}
            </Routes>
          </main>;
  }

  const createRoutes = (routes: AppRoute[]) => {
    return routes.map((route : AppRoute) => {
      return (<Route path={route.path} Component={route.component} key={route.path} />);
    })
  }

  const ErrorComponent = (error: any) => {
    console.log(error);
    return (<>Sorry, there was an error...</>)
  }

  const LoadingComponent = () => {
    return (<>Loading...</>)
  }

  return (
    <div className="App">
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent}
                loadingComponent={LoadingComponent}
            >
              <Box sx={{ display: 'flex' }}>
                <CssBaseline/>
                <AppBar position="fixed">
                  <Toolbar>
                    <Grid container width={'100%'} spacing={2}>
                      <Grid item>
                        <Link to={'/'} className="App-logo"><img src={logo} alt="logo" /></Link>
                      </Grid>
                      <Grid item sx={{ flexGrow: 1 }}>
                        
                      </Grid>
                      <Grid item sx={{ flexShrink: 0 }}>
                        <Auth />
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>
                {isAuthenticated ? renderContent() : renderUnauthorisedContent()}
              </Box>
          </MsalAuthenticationTemplate>
      </MsalProvider>
    </div>
  );
}

export default App;
